import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Noop } from '../../../extViewmodels/Utils';
import { bs } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { styleSheet } from './styles';

type ButtonKind = 'primary' | 'secondary' | 'success' | 'link' | 'destructive' | 'reverse' | 'icon' | 'custom';
interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	accessory?: JSX.Element;
	className?: string;
	kind?: ButtonKind;
	label?: string | JSX.Element;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	size?: 'normal' | 'small';
	styles?: StyleDeclarationValue[];
	isLoading?: boolean;
	children?: React.ReactNode;
}

export const Button: React.ForwardRefExoticComponent<IProps & React.RefAttributes<HTMLButtonElement>> =
	React.forwardRef(
		(
			{
				accessory,
				label,
				kind: type = 'primary',
				size = 'normal',
				onClick,
				className,
				styles,
				disabled,
				isLoading,
				children,
				...otherProps
			},
			ref
		) => {
			const style = getButtonStyle();

			return (
				<button
					className={`${style} ${disabled && css(bs.disabled)} ${css(...(styles || []))} ${className || ''}`}
					onClick={disabled || isLoading ? Noop : onClick}
					disabled={disabled || isLoading}
					ref={ref}
					{...otherProps}
				>
					{isLoading ? (
						<LoadingSpinner type='tiny' />
					) : (
						<span>
							{type === 'icon' && accessory}
							{label || children}
						</span>
					)}
				</button>
			);

			interface IButtonStyles {
				normal?: string;
				small?: string;
			}

			function getButtonStyle() {
				const buttonStyles: Record<ButtonKind, IButtonStyles> = {
					primary: {
						normal: css(bs.ctaButton),
						small: css(bs.ctaButtonSmall),
					},
					secondary: {
						normal: css(bs.ctaButtonSecondary),
					},
					success: {
						normal: css(bs.ctaButton, styleSheet.success),
						small: css(bs.ctaButtonSmall, styleSheet.success),
					},
					link: {
						normal: css(bs.ctaButton, styleSheet.link),
					},
					destructive: {
						normal: css(bs.ctaButton, bs.ctaButtonDestructive),
						small: css(bs.ctaButtonSmall, bs.ctaButtonDestructiveSmall),
					},
					reverse: {
						normal: css(bs.ctaButtonReverse),
						small: css(bs.ctaButtonReverseSmall),
					},
					icon: {
						normal: css(styleSheet.ctaWithIcon),
					},
					custom: {},
				};

				// if the button type is not found, return an empty string
				if (!buttonStyles[type]) {
					return '';
				}

				// if the size is not found, return the normal style
				if (!buttonStyles[type][(size as keyof (typeof buttonStyles)[keyof typeof buttonStyles]) || 'normal']) {
					return buttonStyles[type].normal;
				}

				// return the style for the size
				return buttonStyles[type][(size as keyof (typeof buttonStyles)[keyof typeof buttonStyles]) || 'normal'];
			}
		}
	);

Button.displayName = 'Button';
